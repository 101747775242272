import React from "react";
import MyLink from "./MyLink";

interface Props {
  title: string;
  items: Item[];
  className?: string;
}

interface Item {
  title: string;
  href: string;
  src: string;
  description?: string;
  imageUrl?: string;
  position?: string;
}

export default function ResourceSection({ title, items, className }: Props) {
  var mainClass = "relative max-w-7xl mx-auto mb-24";
  if (className) {
    mainClass = mainClass + " " + className;
  }
  return (
    <div className={mainClass}>
      <h3 className="sm:text-4xl text-3xl font-extrabold tracking-tight text-my-orange ">
        {title}
      </h3>
      <div className="mt-6 sm:mt-8 mx-auto grid gap-5 sm:grid-cols-2 lg:grid-cols-3 lg:max-w-none">
        {items.map((resource) => (
          <div
            key={resource.title}
            className="bg-white flex flex-col rounded-lg shadow-lg overflow-hidden"
          >
            <MyLink
              eventName="resource_clicked"
              details={{ resource: resource.title }}
              href={resource.href}
              linkType="outbound_link"
            >
              <img
                className={`flex-shrink-0 h-60 w-full object-cover ${
                  resource.position ? resource.position : "object-top"
                }`}
                // style={{ marginTop: -80 }}
                src={resource.src}
                alt=""
              />
            </MyLink>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <MyLink
                  eventName="resource_clicked"
                  details={{ resource: resource.title }}
                  href={resource.href}
                  linkType="outbound_link"
                  className="block mt-2"
                >
                  <p className="text-xl font-semibold text-gray-900">
                    {resource.title}
                  </p>
                </MyLink>
                {resource.description ? (
                  <p className="mt-3 text-base text-gray-500">
                    {resource.description}
                  </p>
                ) : undefined}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
