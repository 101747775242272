/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/seo";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ResourceSection from "../components/ResourceSection";

// @ts-ignore
import feelio from "../images/resources/feelio_blocks.jpg";
// @ts-ignore
import yoloha from "../images/resources/yoloha_yoga.jpg";
// @ts-ignore
import wristore from "../images/resources/wristore.jpeg";
// @ts-ignore
import trainings from "../images/resources/teacher_trainings.jpeg";
// @ts-ignore
import alo from "../images/resources/alo_moves.jpeg";
// @ts-ignore
import omstars from "../images/resources/omstars.jpeg";
// @ts-ignore
import anthony from "../images/resources/anthony.png";

export default function Resources(props: PageProps) {
  return (
    <div className="bg-my-tan">
      <SEO title="Resources" image={trainings} />
      <NavBar {...props} />
      <main>
        <div className="relative bg-my-tan pt-8 sm:pt-16 px-4 sm:px-6 lg:px-8">
          <div className="absolute inset-0">
            <div className="bg-white h-1/2 sm:h-1/2" />
          </div>
          <ResourceSection title="Discounted Favorites" items={discounts} />
          <ResourceSection title="Trainings & Classes" items={resources} />
          <ResourceSection
            title="Teaching Collaborators"
            items={collaborators}
          />
        </div>
      </main>
      <Footer />
    </div>
  );
}

const discounts = [
  {
    title: "Feelio Blocks",
    href: "https://feelioblocks.com/discount/ERINSFAVBLOCKS?redirect=%2Fproducts%2Ftahoe&utm_source=erin",
    description: "Discount Code: ERINSFAVBLOCKS",
    imageUrl:
      "https://cdn.shopify.com/s/files/1/0508/4076/4572/products/IMG_6633_2_1080x.jpg?v=1621970024",
    src: feelio,
    position: "object-center",
  },
  {
    title: "Yoloha Yoga",
    href: "https://yolohayoga.com//?ref=bb-gd75dk",
    description: "Discount Code: ERINKELLYART",
    imageUrl:
      "https://cdn.shopify.com/s/files/1/0595/3011/7308/files/plastic-free-shipping_05ac93db-21b1-4418-8e48-6517ae456950.jpg?v=1642098693",
    src: yoloha,
    position: "object-center",
  },
  {
    title: "Wristoring your Practice",
    href: "https://teach.authenticmovements.com/courses/wristore-your-practice/",
    description: "Discount Code: WRISTY50",
    imageUrl:
      "https://teach.authenticmovements.com/wp-content/uploads/2021/12/course-image-cropped-980x752.jpg",
    src: wristore,
    position: "object-center",
  },
];

const resources = [
  {
    title: "Teacher Trainings and More",
    href: "https://authenticmovements.com/trainings/",
    // description: "Continued Education",
    imageUrl:
      "https://authenticmovements.com/wp-content/uploads/2018/11/IMG_0404.jpg",
    src: trainings,
    position: "object-bottom",
  },
  {
    title: "Alo Moves",
    href: "https://www.alomoves.com/instructors/erin?instructors=erin&order=date&",
    // description: "Classes",
    // description: "LIVE Classes via Zoom",
    imageUrl:
      "https://dplvxv40qur9n.cloudfront.net/00b791d6-b787-47a8-b45b-3523f5544c0b.jpg",
    src: alo,
  },
  {
    title: "Omstars",
    href: "https://omstars.com/hosts/erin-kelly",
    // description: "Omstars",
    // description: "Courses and programs",
    imageUrl: "https://omstars.com/static/images/erinsteph-td-2.jpg",
    src: omstars,
    position: "object-bottom",
  },
  // {
  //   title: "Authentic Movement School",
  //   href: "https://authenticmovements.com/",
  //   description: "Authentic Movement School",
  //   imageUrl:
  //     "https://authenticmovements.com/wp-content/uploads/2019/02/AuthenticMovementsLogo_Version02_smoother-1080x1199.png",
  // },
];

const collaborators = [
  {
    title: "Anthony DiFiglio",
    href: "https://anthony.difiglio.com/?utm_source=erin",
    description: "Train with Anthony",
    imageUrl: "",
    src: anthony,
    position: "object-top",
  },
];
